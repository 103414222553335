import React from 'react';
import PanelTitle from '../../../Reusables/PanelTitle';
import { rowType } from './AssetDetails';
import { HStack, Primitive, Text, Tooltip, VStack } from '@rtkwlf/fenrir-react';
import { getAssetTypeImage } from './utils';
import { SOURCE_TEXT } from '../constants';
import { RendererProps } from './InfoRowRenderers/types';
import { CriticalityProps } from './InfoRowRenderers/CriticalityRenderer';
import { iAssetProfileDetails } from './types';

/**
 * Display the field value as is, plus an on-hover tooltip with the same content.
 */
const DefaultRenderer: React.FC<RendererProps> = ({ dataKey, data }) => {
  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Text textColor='infoDefault' truncate>
          {data[dataKey]}
        </Text>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Tooltip.Text>{`${data[dataKey]}`}</Tooltip.Text>
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

/**
 * Display the source type (user-friendly) text along with the appropriate image.
 */
const SourceRenderer: React.FC<RendererProps> = ({ dataKey, data }) => {
  const source = data.source as string;

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <Text textColor='infoDefault' truncate>
          <i
            style={{ marginRight: 5 }}
            className={`fa ${getAssetTypeImage(source)}`}
          />
          {SOURCE_TEXT[source]}
        </Text>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Tooltip.Text>{SOURCE_TEXT[source]}</Tooltip.Text>
      </Tooltip.Content>
    </Tooltip.Root>
  );
};

type StateProps = {
  title: string;
  columns: Array<rowType>;
  data: iAssetProfileDetails;
  infoHeader?: React.ReactNode;
  profileImage?: React.ReactNode;
  renderers?: Record<
    string,
    React.FC<RendererProps> | React.FC<CriticalityProps>
  >;
};

export type Props = StateProps;

const InfoRows = ({
  title,
  columns,
  data,
  infoHeader,
  profileImage,
  renderers,
}: Props) => {
  return (
    <VStack gap='smedium'>
      <HStack yAlign='top' xAlign='between' width='full'>
        <HStack yAlign='top' gap='zero'>
          <PanelTitle title={title} />
          {profileImage ?? null}
        </HStack>
        {infoHeader}
      </HStack>
      {columns.map((column, index) => {
        const Renderer = renderers?.[column.key] ?? DefaultRenderer;
        return (
          <HStack xAlign='between' width='full' key={`${column.key}-${index}`}>
            <Primitive.div maxWidth='50%'>
              <Text userSelect='all' styledAs='body2'>
                {column.label}
              </Text>
            </Primitive.div>
            <Primitive.div maxWidth='50%'>
              <Renderer data={data} dataKey={column.key} />
            </Primitive.div>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default InfoRows;

export { SourceRenderer };
